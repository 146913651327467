import React from "react"

import "./src/sass/main.scss"
import "./src/components/layout.css"
import "./src/styles/tw.css"

import { RedirectProvider } from "./src/context/redirect-context"
import { ReadNewPolicyProvider } from "./src/context/read-new-policy-context"

/*export const onRenderBody = (
  { setHeadComponents },
  pluginOptions
) => {
  setHeadComponents([
      <script type='text/javascript' dangerouslySetInnerHTML={{__html: "var _d_site = _d_site || '17A1F29841A4C23D7174A1E6';"}}></script>,
      <script src='https://widget.privy.com/assets/widget.js'></script>,
      <script async src="https://js.alpixtrack.com/alphpixel.js?cust=3818848-820-KGW"></script>,
  ])
}*/

export const wrapRootElement = ({ element, props }) => (
  <>
    {" "}
    {/*<ReadNewPolicyProvider {...props}>*/}
    <RedirectProvider {...props}>{element}</RedirectProvider>
    {/*</ReadNewPolicyProvider>*/}
  </>
)
