import React, { createContext, useState } from "react";

const RedirectContext = createContext();

function RedirectProvider(props){
  const [redirectActive, setRedirectActive] = useState(false)

  return(
    <RedirectContext.Provider value={[redirectActive, setRedirectActive]}>
      {props.children}
    </RedirectContext.Provider>
  )
}

export default RedirectContext

export { RedirectProvider }